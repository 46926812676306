import {
  PushParamValue,
  useUrlSearchParamsTools,
} from "../../utils/useUrlSearchParamsTools";
import { useCallback, useEffect, useMemo, useState } from "react";
import { parseNumericParam } from "../../utils/parseNumericParam";
import { allSchoolLevels, SchoolLevel } from "../../slices/products/types";
import { SchoolLevelFilterValue } from "./types";

const localStorageLabel = "shopCatalog";

const defaultPage = 1;
const separator = ",_,";

const defaultPageValue: PushParamValue = {
  paramName: "page",
  value: JSON.stringify(defaultPage),
};

export const useShopCatalogParams = () => {
  const { urlSearchParams, pushParam } =
    useUrlSearchParamsTools(localStorageLabel);

  const { search, minPrice, maxPrice, ...parsedParams } = useMemo(() => {
    return {
      search: urlSearchParams.get("search") ?? "",
      page: parseNumericParam(urlSearchParams.get("page")) ?? defaultPage,
      minPrice:
        parseNumericParam(urlSearchParams.get("minPrice"), true) ?? null,
      maxPrice:
        parseNumericParam(urlSearchParams.get("maxPrice"), true) ?? null,
      tags:
        urlSearchParams
          .get("tags")
          ?.split(separator)
          .filter((value) => value.length > 0) ?? [],
      schoolLevels: (() => {
        const values = urlSearchParams.get("schoolLevels")?.split(separator) as
          | SchoolLevel[]
          | null;

        const unserialized: Record<SchoolLevel, boolean> = {
          earlyYears: false,
          primarySchool: false,
          secondarySchool: false,
        };

        for (const value of allSchoolLevels) {
          unserialized[value] = !!values?.find(
            (schoolLevel) => schoolLevel === value
          );
        }

        return unserialized;
      })(),
    };
  }, [urlSearchParams]);

  const [uiSearch, setUiSearch] = useState(search);

  const searchChangeHandler = useCallback((value: string) => {
    setUiSearch(value);
  }, []);

  const [uiMinPrice, setUiMinPrice] = useState<number | null>(null);

  const minPriceChangeHandler = useCallback((value: number | null) => {
    setUiMinPrice(value);
  }, []);

  const [uiMaxPrice, setUiMaxPrice] = useState<number | null>(null);

  const maxPriceChangeHandler = useCallback((value: number | null) => {
    setUiMaxPrice(value);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const toPush: PushParamValue[] = [];

      if (uiSearch !== search) {
        toPush.push({ paramName: "search", value: uiSearch });
      }

      if (uiMinPrice !== minPrice) {
        toPush.push({
          paramName: "minPrice",
          value:
            typeof uiMinPrice === "number" && !isNaN(uiMinPrice)
              ? JSON.stringify(uiMinPrice)
              : "",
        });
      }

      if (uiMaxPrice !== maxPrice) {
        toPush.push({
          paramName: "maxPrice",
          value:
            typeof uiMaxPrice === "number" && !isNaN(uiMaxPrice)
              ? JSON.stringify(uiMaxPrice)
              : "",
        });
      }

      if (toPush.length > 0) {
        pushParam([...toPush, defaultPageValue]);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [maxPrice, minPrice, pushParam, search, uiMaxPrice, uiMinPrice, uiSearch]);

  const pageChangeHandler = useCallback(
    (value: number) => {
      pushParam({ paramName: "page", value: JSON.stringify(value) });
    },
    [pushParam]
  );

  const tagsChangeHandler = useCallback(
    (tags: string[]) => {
      pushParam([
        { paramName: "tags", value: tags.join(separator) },
        defaultPageValue,
      ]);
    },
    [pushParam]
  );

  const schoolLevelsChangeHandler = useCallback(
    (schoolLevels: Partial<SchoolLevelFilterValue>) => {
      pushParam([
        {
          paramName: "schoolLevels",
          value: Object.entries(schoolLevels)
            .filter(([, value]) => value)
            .map(([key]) => key)
            .join(separator),
        },
        defaultPageValue,
      ]);
    },
    [pushParam]
  );

  const resetFiltersHandler = useCallback(
    (search = "") => {
      setUiSearch(search);
      searchChangeHandler(search);
      pageChangeHandler(defaultPage);
      minPriceChangeHandler(null);
      maxPriceChangeHandler(null);
      tagsChangeHandler([]);
      schoolLevelsChangeHandler({});
    },
    [
      maxPriceChangeHandler,
      minPriceChangeHandler,
      pageChangeHandler,
      schoolLevelsChangeHandler,
      searchChangeHandler,
      tagsChangeHandler,
    ]
  );

  return {
    ...parsedParams,
    search,
    uiSearch,
    searchChangeHandler,
    pageChangeHandler,
    minPrice,
    minPriceChangeHandler,
    maxPrice,
    maxPriceChangeHandler,
    tagsChangeHandler,
    schoolLevelsChangeHandler,
    resetFiltersHandler,
  };
};
